import React from 'react';
import { MDBRow, MDBBtn } from 'mdbreact';
import resumeFile from '../../assets/christian-resume.pdf';
import './ResumeText.css';

// i18n
import '../../i18n';
import { withNamespaces } from 'react-i18next';

const ResumeText = ({ t }) => {
  return (
    <div className="resume-text">
      <br />
      <br />
      <br />

      <h2 className="h1-responsive font-weight-bold my-5">
        {t('ResumeText.title')}
      </h2>
      <p className="text-container text-left lead grey-text w-responsive mx-auto mb-5">
        {t('ResumeText.text')}
        &#128077; &#x1F609;
      </p>
      <br />
      <br />
      <MDBRow className="d-block">
        <a href={resumeFile} download>
          <MDBBtn>{t('ResumeText.downloadBtnText')}</MDBBtn>
        </a>
      </MDBRow>

      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default withNamespaces()(ResumeText);
