import React, { Component } from 'react';
import Layout from '../components/Layout/Layout';
import { MDBContainer } from 'mdbreact';
import ResumeText from '../components/ResumeText/ResumeText';

const App = () => {
  return (
    <>
      <Layout>
        <main>
          <MDBContainer tag="section" className="text-center my-5">
            <ResumeText />
            <br />
            <br />
          </MDBContainer>
        </main>
      </Layout>
    </>
  );
};

export default App;
